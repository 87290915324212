@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/custom-icons/icomoon.eot?89thsy');
  src:  url('/assets/fonts/custom-icons/icomoon.eot?89thsy#iefix') format('embedded-opentype'),
    url('/assets/fonts/custom-icons/icomoon.ttf?89thsy') format('truetype'),
    url('/assets/fonts/custom-icons/icomoon.woff?89thsy') format('woff'),
    url('/assets/fonts/custom-icons/icomoon.svg?89thsy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="as-icon-"], [class*=" as-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.as-icon-Agents:before {
  content: "\e900";
}
.as-icon-Pos:before {
  content: "\e901";
}
.as-icon-Roles:before {
  content: "\e911";
}

.as-icon-Casino:before {
  content: "\e904";
}

.as-icon-Dashboard:before {
  content: "\e906";
}
.as-icon-Players:before {
  content: "\e90e";
}

.as-icon-Adjust-Credit:before {
  content: "\e902";
}
.as-icon-Users:before {
  content: "\e904";
}
.as-icon-Reports:before {
  content: "\e905";
}
.as-icon-Summary:before {
  content: "\e906";
}
.as-icon-Terminals:before {
  content: "\e907";
}
.as-icon-Collapse-Menu-left:before {
  content: "\e994";
}
.as-icon-Collapse-Menu-right:before {
  content: "\e994";
}
.as-icon-Icons_Notification:before {
  content: "\e90d";
}
.as-icon-Icons_Active-Session:before {
  content: "\e999";
}
.as-icon-Icons_Timezone:before {
  content: "\e914";
}
.as-icon-Subaccounts:before {
  content: "\e902";
}
.as-icon-Icons_Adjust-Money:before {
  content: "\e997";
}
.rotate--icon-right {
  transform: rotate(-180deg);
}

.as-icon-Close:before {
  content: "\e995";
}

.as-icon-Search:before {
  content: "\e912";
}

.as-icon-Delete:before {
  content: "\e992";
}

.as-icon-Arrow:before {
  content: "\e996";
}

.as-icon-Add-Note:before {
  content: "\e998";
}

.as-icon-Logout:before {
  content: "\e901";
}

.as-icon-Menu:before {
  content: "\e90c";
}
.as-icon-Bonus:before {
  color: black;
  content: "\e90b";
}

.as-icon-refresh:before {
  color: black;
  content: "\e90f";
}
